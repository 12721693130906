@import 'styles/_theme.scss';

.headerContainer {
  display: flex;
  align-items: center;
  background-color: $darkPrimary;
  color: $white;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}

.logoContainer{
  display: flex;
}

.logo {
  background-color: $darkPrimary;
  padding-left: $ss-12;
  padding-right: $ss-16;
  padding-top: $px-20;
  border: 2px dashed $darkPrimary;
  height: 68px;
  &:focus-visible{
    border: 2px dashed $white;
    outline: none;
  }
}

.lbutton, .rbutton {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 0px;
  border: none;
  background-color: $darkPrimary;
  padding-top: 0;
  padding-bottom: 0;
  &:hover{
    cursor: pointer;
  }
}

.tabsContainer{
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.logoHW {
  width: 440px;
}

.profile {
  background-color: $primary-color;
  height: 72px;
  padding: 0 $ss-10;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-left: auto;
}

.accountIcon {
  height: $ss-38;
  width: $ss-38;
  margin-left: $ss-10;
  margin-right: $ss-14;
}

.accountWrapper {
  height: $ss-38;
  font-size: $ts-18;
  margin-right: $ss-10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $ss-4;
}

.account {
  display: flex;
  height: $ss-24;
  align-items: center;
}

.appName {
  font-size: $ts-12;
  font-style: italic;
}

.navBarContainer {
  height: 100%;
  margin-right: auto;
  width: 100%;
}

.navBar {
  display: flex;
  gap: $ss-24;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 100%;
  width: 100%;
  font-size: $ts-18;
  list-style-type: none;
  overflow-x: hidden;
  position: relative;
  right: 0;

  & li {
    display: flex;
    max-height: 100%;
    min-height: 100%;
  }
  & a {
    color: $white;
    text-decoration: none;
    padding: $ss-20 $ss-18 $ss-10;
    &:hover {
      border-bottom: $ss-5 solid $primary-color;
      background-color: $primary-color;
    }
    &:focus-visible {
      border: 2px dashed $white;
      outline: none;
    }
    &[disabled] {
      pointer-events: none;
    }
  }
}

.noData {
  max-height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.active {
  border-bottom: $ss-5 solid $red !important;
  font-weight: bold;
}

.navTab{
  white-space: nowrap;
}